import styled from "@emotion/styled";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const A = styled.a`
  word-break: break-all;
`;
