import React from "react";
import Typography from "../../components/Typography";
import Section from "../../components/Section";
import { Column, A } from "./styles";

const Privacy = () => {
  return (
    <Section shrinkTop>
      <Column>
        <Typography variant="h1">Privacy Policy</Typography>
        <p>
          Outline Software, Inc. (“Outline,” “we,” “us,” and “our”) provides a
          platform that enables Customer Success and Product teams to
          collaborate and manage customers. Our Privacy Policy (“Privacy
          Policy”) is designed to help you understand how we collect, use and
          share your personal information and to assist you in exercising the
          privacy rights available to you.
        </p>
        <h2>
          <strong>Scope</strong>
        </h2>
        <p>
          This Privacy Policy covers the personal information we collect about
          you when you use our products or services, or otherwise interact with
          us, including on our website at{" "}
          <A
            href="http://www.heyoutline.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.heyoutline.com
          </A>
          , API, and our related online and offline offerings (collectively, the
          “<strong>Services</strong>”). This policy also explains your choices
          surrounding how we use your personal information, which include how
          you can object to certain uses of the information and how you can
          access and update certain information.
        </p>
        <h2>1. Personal Information we collect</h2>
        <p>
          We collect personal information when you provide it to us, when you
          use our Services, and when other sources provide it to us, as further
          described below.
        </p>
        <p>
          <strong>A. Information You Provide to Us</strong>
        </p>
        <p>
          <strong>Account Creation:</strong> When you create an account or
          otherwise use the Services, we collect information such as your name,
          email address, password, role within your team or enterprise, and an
          optional profile photo.
        </p>
        <p>
          <strong>Your Communications with Us:</strong> We collect personal
          information from you such as email address, phone number, or mailing
          address when you request information about our Services, register for
          our newsletter, request customer or technical support, or otherwise
          communicate with us. We also collect the contents of messages or
          attachments that you may send to us, as well as other information you
          choose to provide, and that may be associated with your
          communications.
        </p>
        <p>
          <strong>Payment Information:</strong> When you choose to subscribe for
          a Personal or Enterprise plan on the Services, we will collect payment
          information allowing you to pay us. We use third-party payment
          providers to process payments on the Services. We may receive
          information associated with your payment information, such as billing
          address and transaction information, but we do not directly store
          payment information on the Services.
        </p>
        <p>
          <strong>Surveys:</strong> We may contact you to participate in
          surveys. If you decide to participate, you may be asked to provide
          certain information which may include personal information.
        </p>
        <p>
          <strong>Interactive Features:</strong> We may offer interactive
          features such as forums, blogs, chat and messaging services, and
          social media pages. We and others who use our Services may collect the
          information you submit or make available through these interactive
          features. Any content you provide via the public sections of these
          features will be considered “public” and is not subject to the privacy
          protections referenced herein. By using these interactive features,
          you understand that the personal information provided by you may be
          viewed and used by third parties for their own purposes.
        </p>
        <p>
          <strong>Registration for Sweepstakes or Contests:</strong> We may run
          sweepstakes and contests. Contact information you provide may be used
          to inform you about the sweepstakes or contest and for other
          promotional, marketing and business purposes.
        </p>
        <p>
          <strong>Conferences, Trade Shows, and Other Events:</strong> We may
          attend conferences, trade shows, and other events where we collect
          personal information from individuals who interact with or express an
          interest in the Services.
        </p>
        <p>
          <strong>Job Applications</strong>. We may post job openings and
          opportunities on the Services. If you reply to one of these postings
          by submitting your application, CV and/or cover letter to us, we will
          collect and use your information to assess your qualifications.
        </p>
        <p>
          <strong>B. Information Collected Automatically</strong>
        </p>
        <p>
          <strong>Automatic Data Collection:</strong> We keep track of certain
          information about you when you visit and interact with any of our
          Services. This information may include your Internet protocol (IP)
          address, user settings, MAC address, cookie identifiers, details about
          your browser, operating system or device, location information
          (including inferred location based off of your IP address), Internet
          service provider, information about how you interact with the
          Services, including the frequency and duration of your activities, and
          other information about how you use the Services. Information we
          collect may be associated with accounts and other devices.
        </p>
        <p>
          <strong>
            Cookies, Pixel Tags/Web Beacons, and Analytics Information:
          </strong>{" "}
          We, as well as third parties that provide content, advertising, or
          other functionality on the Services, may use cookies, pixel tags,
          local storage, and other technologies (“Technologies”) to
          automatically collect information through the Services. Technologies
          are essentially small data files placed on your devices that allow us
          and our partners to record certain pieces of information whenever you
          visit or interact with our Services.
        </p>
        <ul>
          <li>
            <strong>Cookies:</strong> Cookies are small text files placed in
            device browsers to store their preferences. Most browsers allow you
            to block and delete cookies. However, if you do that, the Services
            may not work properly.
          </li>
          <li>
            <strong>Pixel Tags/Web Beacons:</strong> A pixel tag (also known as
            a web beacon) is a piece of code embedded in the Services that
            collects information about engagement on the Services. The use of a
            pixel tag allows us to record, for example, that a user has visited
            a particular web page or clicked on a particular advertisement. We
            may also include web beacons in e-mails to understand whether
            messages have been opened, acted on, or forwarded.
          </li>
        </ul>
        <p>
          <strong>Analytics:</strong> We may also use third-party service
          providers to collect and process analytics and other information on
          our Services. These third-party service providers may use cookies,
          pixel tags, web beacons or other storage technology to collect and
          store analytics and other information. They have their own privacy
          policies addressing how they use the analytics and other information
          and we do not have access to, nor control over, third parties’ use of
          cookies or other tracking technologies.
        </p>
        <p>
          <strong>C. Information from Other Sources</strong>
        </p>
        <p>
          We may obtain information about you from other sources, including
          through third-party services and organizations. For example, if you
          access our Services through a third-party application, such as a
          third-party login service, we may collect information about you from
          that third party that you have made available via your privacy
          settings.
        </p>
        <p>
          Should you choose to use the Google Calendar feature within our
          Services, we will have the ability to view your calendars and
          associated events via the Google Calendars and Events API. The sole
          use of this data is to populate your homepage and upcoming customer
          events within the Services. Outline will not use this data for any
          other purpose.
        </p>

        <h2>2. How we use your information</h2>
        <p>
          We use your personal information for a variety of business purposes,
          including to:
        </p>
        <p>
          <strong>
            Provide the Services or Requested Information, such as:
          </strong>
        </p>
        <ul>
          <li>Fulfilling our contract with you;</li>
          <li>
            Identifying and communicating with you, including providing
            newsletters and marketing materials;
          </li>
          <li>Managing your information;</li>
          <li>
            Processing your payments and otherwise servicing your purchase
            orders;
          </li>
          <li>Responding to questions, comments, and other requests;</li>
          <li>
            Providing access to certain areas, functionalities, and features of
            our Services; and
          </li>
          <li>Answering requests for customer or technical support.</li>
        </ul>
        <p>
          <strong>Serve Administrative Purposes, such as:</strong>
        </p>
        <ul>
          <li>
            Pursuing legitimate interests, such as direct marketing, research
            and development (including marketing research), network and
            information security, and fraud prevention;
          </li>
          <li>
            Measuring interest and engagement in our Services, including
            analyzing your usage of the Services;
          </li>
          <li>
            Developing new products and services and improving the Services;
          </li>
          <li>Ensuring internal quality control and safety;</li>
          <li>Authenticating and verifying individual</li>
          <li>Carrying out audits;</li>
          <li>
            Communicating with you about your account, activities on our
            Services and Privacy Policy changes;
          </li>
          <li>
            Preventing and prosecuting potentially prohibited or illegal
            activities;
          </li>
          <li>Enforcing our agreements; and</li>
          <li>Complying with our legal obligations.</li>
        </ul>
        <p>
          <strong>Marketing Our Products and Services:</strong> We may use
          personal information to tailor and provide you with content and
          advertisements. If you have any questions about our marketing
          practices or if you would like to opt out of the use of your personal
          information for marketing purposes, you may contact us as set forth
          below.
        </p>
        <p>
          <strong>Consent:</strong> We may use personal information for other
          purposes that are clearly disclosed to you at the time you provide
          personal information or with your consent.
        </p>
        <p>
          <strong>De-identified and Aggregated Information Use:</strong> We may
          use personal information and other data about you to create
          de-identified and/or aggregated information. De-identified and/or
          aggregated information is not personal information, and we may use and
          disclose such information in a number of ways, including research,
          internal analysis, analytics, and any other legally permissible
          purposes.
        </p>
        <p>
          <strong>Process Information on Behalf of Our Customers:</strong> Our
          customers may choose to use our Services to process certain data of
          their own, which may contain personal information. Such personal
          information that is processed by us on behalf of our customers, and
          our privacy practices will be governed by the contracts that we have
          in place with our customers, not this Privacy Policy.
        </p>
        <p>
          If you have any questions or concerns about how such personal
          information is handled or would like to exercise your rights, you
          should contact the person or entity (i.e., the data controller) who
          has contracted with us to use the Service to process this information.
          Our customers control the personal information in these cases and
          determine the security settings within the account, its access
          controls and credentials. We will, however, provide assistance to our
          customers to address any concerns you may have, in accordance with the
          terms of our contract with them.
        </p>
        <p>We use the following sub-processors:</p>
        <ul>
          <li>
            <A
              href="https://aws.amazon.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon Web Services
            </A>{" "}
            (USA)
          </li>
          <li>
            <A
              href="https://sentry.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sentry
            </A>{" "}
            (USA)
          </li>
          <li>
            <A
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </A>{" "}
            (USA)
          </li>
        </ul>
        <p>
          <strong>How We Use Automatic Collection Technologies:</strong> Our
          uses of Technologies fall into the following general categories:
        </p>
        <ul>
          <li>Operationally Necessary;</li>
          <li>Performance Related;</li>
          <li>Functionality Related; and</li>
          <li>Advertising or Targeting Related.</li>
        </ul>
        <p>
          <strong>Cross-Device Tracking:</strong> Your browsing activity may be
          tracked across different websites and different devices or apps. For
          example, we may attempt to match your browsing activity on your mobile
          device with your browsing activity on your laptop. To do this our
          technology partners may share data, such as your browsing patterns,
          geo-location and device identifiers, and will match the information of
          the browser and devices that appear to be used by the same person.
        </p>
        <h2>3. Disclosing your information to third parties</h2>
        <p>
          We may share your personal information with the following categories
          of third parties:
        </p>
        <p>
          <strong>Service Providers:</strong> We may share any personal
          information we collect about you with our third-party service
          providers. The categories of service providers to whom we entrust
          personal information include service providers for: (i) the provision
          of the Services; (ii) the provision of information, products, and
          other services you have requested; (iii) marketing and advertising;
          (iv) payment and transaction processing; (v) customer service
          activities; and (vi) the provision of IT and related services.
        </p>
        <p>
          <strong>Business Partners:</strong> We may provide personal
          information to business partners to provide you with a product or
          service you have requested. We may also provide personal information
          to business partners with whom we jointly offer products or services.
        </p>
        <p>
          <strong>Affiliates:</strong> We may share personal information with
          our affiliated entities.
        </p>
        <p>
          <strong>Advertising Partners:</strong> We do not use or share your
          information, including personal information, to advertise any third
          party’s products or services via the Services. We may use and share
          your personal information with third-party advertising partners to
          market our own Services and grow our Services’ user base, such as to
          provide targeted marketing about our own Services via third-party
          services. If you prefer not to share your personal information with
          third-party advertising partners, you may follow the instructions
          below.
        </p>
        <p>
          We may share your personal information with other third parties,
          including other users, in the following circumstances:
        </p>
        <p>
          <strong>Workspaces with Mulitple Users:</strong> When you submit
          personal information in a workspace that can be accessed by others,
          such personal information may be displayed to other users in the same
          or connected workspaces. For example, your personal information may be
          included in your notes, tasks, or comments in a workspace which can be
          viewed by other users collaborating with you in that workspace.
          Further, your email address or photo may be displayed with your
          workspace profile to other users collaborating with you in that
          workspace.
        </p>
        <p>
          <strong>Enterprise Workspaces:</strong> We will share and disclose
          Enterprise Content in accordance with an Enterprise’s instructions,
          including any applicable terms in the Subscription Agreement and an
          Enterprise administrator’s use of the Services’ administrative
          functionality, and in compliance with applicable law and legal
          process. We may also allow an Enterprise to access the profile
          information of Authorized Users, including to allow such Enterprise to
          validate that such Authorized User is actually authorized by such
          Enterprise to use the Services.
        </p>
        <p>
          <strong>Disclosures to Protect Us or Others:</strong> We may access,
          preserve, and disclose any information we store in association with
          you to external parties if we, in good faith, believe doing so is
          required or appropriate to: (i) comply with law enforcement or
          national security requests and legal process, such as a court order or
          subpoena; (ii) protect your, our, or others’ rights, property, or
          safety; (iii) enforce our policies or contracts; (iv) collect amounts
          owed to us; or (v) assist with an investigation and prosecution of
          suspected or actual illegal activity.
        </p>
        <p>
          <strong>
            Disclosure in the Event of Merger, Sale, or Other Asset Transfer:
          </strong>{" "}
          If we are involved in a merger, acquisition, financing due diligence,
          reorganization, bankruptcy, receivership, purchase or sale of assets,
          or transition of service to another provider, then your information
          may be sold or transferred as part of such a transaction, as permitted
          by law and/or contract.
        </p>
        <h2>4. International Data Transfers</h2>
        <p>
          All information processed by us may be transferred, processed, and
          stored anywhere in the world, including but not limited to, the United
          States or other countries, which may have data protection laws that
          are different from the laws where you live. We endeavor to safeguard
          your information consistent with the requirements of applicable laws.
        </p>
        <h2>5. Your Choices</h2>
        <p>
          <strong>General:</strong> You may have the right to object to or opt
          out of certain uses of your personal information. Where you have
          consented to the processing of your personal information, you may
          withdraw that consent at any time by contacting us as described below.
          Even if you opt out, we may still collect and use non-personal
          information regarding your activities on our Services and for other
          legal purposes as described above.
        </p>
        <p>
          <strong>Email Communications:</strong> If you receive an unwanted
          email from us, you can use the unsubscribe link found at the bottom of
          the email to opt out of receiving future emails. Note that you will
          continue to receive transaction-related emails regarding products or
          services you have requested. We may also send you certain
          non-promotional communications regarding us and our Services, and you
          will not be able to opt out of those communications (e.g.,
          communications regarding the Services or updates to this Privacy
          Policy).
        </p>
        <p>
          <strong>“Do Not Track”:</strong> Your browser may offer you a “Do Not
          Track” option, which allows you to signal to operators of websites and
          web applications and Service that you do not wish such operators to
          track certain of your online activities over time and/or across
          different websites. If your browser is set to “Do Not Track”, Outline
          will attempt to honor this functionality. However, our third party
          service providers may use their own cookies, pixel tags, web beacons
          or other storage technology to collect and store Log Data or
          information from elsewhere on the internet, and we do not have access
          to, nor control over, a third parties’ use of cookies or other
          tracking technologies.
        </p>
        <p>
          <strong>Cookies and Interest-Based Advertising:</strong> You may stop
          or restrict the placement of Technologies on your device or remove
          them by adjusting your preferences as your browser or device permits.
          The online advertising industry also provides websites from which you
          may opt out of receiving targeted ads from data partners and other
          advertising partners that participate in self-regulatory programs. You
          can access these websites and learn more about targeted advertising
          and consumer choice and privacy, at{" "}
          <A
            href="http://www.networkadvertising.org/managing/opt_out.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.networkadvertising.org/managing/opt_out.asp
          </A>
          ,{" "}
          <A
            href="http://www.youronlinechoices.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.youronlinechoices.eu/
          </A>
          ,{" "}
          <A
            href="https://youradchoices.ca/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://youradchoices.ca/choices/
          </A>
          , and{" "}
          <A
            href="http://www.aboutads.info/choices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aboutads.info/choices/
          </A>
          .
        </p>
        <p>
          Please note you must separately opt out in each browser and on each
          device.
        </p>
        <h2>6. Your Privacy Rights</h2>
        <p>
          Depending upon your location and in accordance with applicable laws,
          you may have the right to:
        </p>
        <ul>
          <li>
            <strong>Export</strong> personal information about you consistent
            with legal requirements. In addition, you may have the right in some
            cases to receive or have your electronic personal information
            transferred to another party.
          </li>
          <li>
            <strong>Correction</strong> of your personal information where it is
            inaccurate or incomplete.
          </li>
          <li>
            <strong>Deletion</strong> of your personal information, subject to
            certain exceptions prescribed by law.
          </li>
          <li>
            <strong>Restriction </strong> of the processing of your personal
            information, including the right to opt in or opt out of the sale of
            your personal information to third parties.
          </li>
          <li>
            <strong>Not be Discriminated Against</strong> by us for exercising
            your privacy rights.
          </li>
        </ul>
        <p>
          If you would like to exercise any of these rights, please contact us
          as set forth below. We will process such requests in accordance with
          applicable laws. To protect your privacy, we will take steps to verify
          your identity before fulfilling your request, such as by requiring you
          to submit your request via your account.
        </p>
        <h2>7. Date Retention</h2>
        <p>
          We store the personal information we receive as described in this
          Privacy Policy for as long as you use our Services or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws.
        </p>
        <h2>8. Security of your Information</h2>
        <p>
          We take steps to ensure that your information is treated securely and
          in accordance with this Privacy Policy. Unfortunately, no system is
          100% secure, and we cannot ensure or warrant the security of any
          information you provide to us. To the fullest extent permitted by
          applicable law, we do not accept liability for unauthorized
          disclosure.
        </p>
        <p>
          By using the Services or providing personal information to us, you
          agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          the Services. If we learn of a security system’s breach, we may
          attempt to notify you electronically by posting a notice on the
          Services, by mail or by sending an email to you.
        </p>
        <h2>9. Third-Party Websites/Applications</h2>
        <p>
          The Services may contain links to other websites/applications and
          other websites/applications may reference or link to our Services.
          These third-party services are not controlled by us. We encourage our
          users to read the privacy policies of each website and application
          with which they interact. We do not endorse, screen or approve, and
          are not responsible for, the privacy practices or content of such
          other websites or applications. Visiting these other websites or
          applications is at your own risk.
        </p>
        <h2>10. Children's Information</h2>
        <p>
          The Services are not directed to children under 13 (or other age as
          required by local law), and we do not knowingly collect personal
          information from children. If you learn that your child has provided
          us with personal information without your consent, you may contact us
          as set forth below. If we learn that we have collected a child’s
          personal information in violation of applicable law, we will promptly
          take steps to delete such information and terminate the child’s
          account.
        </p>
        <h2>11. Supervisory Authority</h2>
        <p>
          If you are located in the European Economic Area or the UK, you have
          the right to lodge a complaint with a supervisory authority if you
          believe our processing of your personal information violates
          applicable law.
        </p>
        <h2>12. California Privacy Notice</h2>
        <p>
          This Privacy Notice applies to California consumers and supplements
          the Privacy Policy.
        </p>
        <p>
          <strong>California Shine the Light Law:</strong> The California “Shine
          the Light” law permits users who are California residents to request
          and obtain from us once a year, free of charge, a list of the third
          parties to whom we have disclosed their personal information (if any)
          for their direct marketing purposes in the prior calendar year, as
          well as the type of personal information disclosed to those parties.
          To make such a request from us, if entitled, please use the contact
          information listed below.
        </p>
        <p>
          <strong>California Consumer Privacy Act (CCPA):</strong> In the
          preceding 12 months, we have not disclosed your personal information
          to any third party in a manner that would be considered a sale under
          the CCPA.
        </p>
        <p>
          <strong>Information We Collect and Disclose</strong>
        </p>
        <p>
          For purposes of the CCPA, we have collected the following categories
          of consumers’ personal information, and disclosed the personal
          information to the following categories of third parties for a
          business purpose, in the last 12 months:
        </p>
        <ul>
          <li>Identifiers: Service providers; advertising partners.</li>
          <li> Commercial information: Service providers.</li>
          <li>
            Internet or other electronic network activity: Service providers;
            advertising partners.
          </li>
          <li>Geolocation data: Service providers; advertising partners.</li>
          <li>Sensory data: Service providers; other users.</li>
          <li>
            Professional or employment-related information: Service providers.
          </li>
          <li>
            Inferences drawn from other personal information to create a profile
            about a consumer: Service providers; advertising partners.
          </li>
          <li>
            Personal information categories listed in the categories above, but
            references in the California Customer Records statute (Cal. Civ.
            Code § 1798.80(e)). Service providers; advertising partners.
          </li>
        </ul>
        <h2>
          13. <strong>Changes to Our Privacy Policy</strong>
        </h2>
        <p>
          We may revise this Privacy Policy from time to time in our sole
          discretion. If there are any material changes to this Privacy Policy,
          we will notify you as required by applicable law. You understand and
          agree that you will be deemed to have accepted the updated Privacy
          Policy if you continue to use the Services after the new Privacy
          Policy takes effect.
        </p>
        <p>
          <strong>Contact us:</strong>
        </p>
        <p>
          If you have any questions about our privacy practices or this Privacy
          Policy, please contact us at:
        </p>
        <p>
          <A href="mailto:security@heyoutline.com">security@heyoutline.com</A>
          <br />
          Outline Software, Inc.
          <br />
          112 S Park St
          <br />
          San Francisco, CA 94107 United States
        </p>
        <p>Effective Date: Oct 10, 2020</p>
      </Column>
    </Section>
  );
};

export default Privacy;
